



















import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { LOGOUT_USER } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "ApprovalPending",
  data() {
    return {
      loading_animation: require("@/assets/illustrations/loading.json")
    };
  },
  created() {
    this.loading_animation = JSON.stringify(this.loading_animation);
    this.root_error("Your Account approval is pending");
  },
  methods: {
    ...mapActions("auth", {
      logout: LOGOUT_USER
    }),
    ...mapMutations({
      root_error: ROOT_ERROR
    })
  }
});
